<template>
  <span class="placeholder">🚧 {{ route.currentRoute.value.fullPath }} 🚧</span>
</template>

<script lang="ts" setup>
const route = useRouter();
</script>

<style lang="scss" scoped>
@use '$/colors.scss';

.placeholder {
  font-size: 24px;
  font-weight: 600;
  color: colors.$green;
  text-align: center;
}
</style>
